body {
  margin: 1rem;
  background-color: lightgray;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.waiting {
    cursor: progress,
}


/* main classes */

.displaynone {
    display: none;
}

.about {
    color: #083757;
}

.main-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
}

.main-container {
    width: 100%;
    margin: auto;
    min-height: 640px;
    padding: 8px 8px 8px 8px;
    background-color: whitesmoke;
    border-style: solid;
    border-color: #0E5C91;
    border-width: 1px;
}

.notification {
    font-size: large;
    padding: 8px;
    background-color: white;
    cursor: default;
}

/* Nav  bootstrap-blue: #0d6efd*/

.footer .navbar-nav .nav-item .nav-link {
    color: #07377e;
}

.footer .navbar-nav .nav-item .nav-link:hover {
    color: #3A86FF;
}

.navbar {
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #083757;
}

.navbar a {
    font-weight: bold;
}

.navbar a:hover {
    color: white;
    cursor:  pointer;
}

.navbar-nav .nav-item .nav-link
{
  font-size: large;
  color: white;
}
@media (max-width: 600px) {
    .navbar-nav .nav-item .nav-link
    {
        font-size: small;
    }
}

.navbar-nav .nav-item .nav-link:hover {
    color: #e0e0e0;
}

.nav-pills .nav-link {
    cursor: pointer;
}

.nav-pills .nav-link.active {
    background-color: #3A86FF;
}

.navbar-brand > img {
    display: inline-block;
    width: 20px;
    margin-bottom: 2px;
    margin-right:  5px;
    border-radius: 2px;
}

ul.nav.navbar-nav li {
    margin: 0 0.5rem;
}

.profile-image {
    height: 36px;
    margin: 4px;
}

.footer {
    padding: 12px;
    background-color: lightgray;
}

/* bmodel classes */

.bmodel-description {
    text-align: center;
    background-color: white;
    cursor: default;
    border-style:  solid;
    border-color:  lightgray;
    border-radius: 4px;
    border-width:  1px;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);
    padding: 12px;
}
@media (max-width: 600px) {
    .bmodel-description {
        padding: 4px;
    }
}

.bmodel-description .col {
    font-size: 24px;
}
@media (max-width: 600px) {
    .bmodel-description .col {
        font-size: 16px;
    }
}

.bmodel-link {
    text-decoration: none;
    font-weight: bolder;
    font-size: medium;
}

.bmodel-list {
    text-align: center;
    width: 80%;
    margin: auto;
}
@media (max-width: 600px) {
    .bmodel-list {
        text-align: center;
        width: 100%;
        margin: auto;
    }
}

.bmodel-item {
    display: flex;
    justify-content: space-between;
    background-color:  white;
    border-style:  solid;
    border-color:  lightgray;
    border-radius: 4px;
    border-width:  1px;
    font-size: 18px;
    padding: 16px 8px 16px 16px;
    margin: 16px;
}

.bmodel-form {
  background-color:  whitesmoke;
  border-color:  lightgray;
  border-style:  solid;
  border-radius: 4px;
  border-width:  1px;
  margin: 0px;
  padding: 8px;
}

.bmodel-delete {
  position: right;
  cursor: pointer;
  background: none;
  border: none;
}

.bmodel-new {
    font-weight: bolder;
    font-size: 24px;
    color: #083757;
    width: 58%;
    text-align: right;
}

.bmodel-download {
    cursor: pointer;
    background: none;
    border: none;
}

/* Style tab links */

.main-tabs .nav-item
{
  font-size: x-large;
}

.main-tab-item {
    box-shadow: 0px 2px 4px rgb(0 0 0 / 0.2);
}

.main-tabs-container {
    background-color: white;
    border-style:  solid;
    border-color:  lightgray;
    border-radius: 4px;
    border-width:  1px;
    padding: 12px;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);
}

.table {
    background-color: whitesmoke;
}

.tablink {
  background-color: #444;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 12px;
  width: 25%;
}

.tablink:hover {
  background-color: #666;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  display: none;
  padding: 4px 4px;  
  height: 100%;
}

.tsplot-container {
    border-style: none;
    margin: 4px;
}

.tsplot {
  background-color: white;
  border-color:  gray;
  border-style:  none;
  border-radius: 2px;
  border-width:  1px;
  width: 100%;
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
}

.tsplot-delete {
    color: #0E5C91;
    position: relative;
    bottom: 5px;
    left: 10px;
    width: 24px;
    height: 24px;
    border-radius: 2px;
}

.input-group-text  {
  text-overflow: clip;
}

.mcmcplot {
    border-color:  lightgray;
    border-style:  solid;
    border-radius: 4px;
    border-width:  2px;
    margin: 4px;
    width: 80%;
}
@media (max-width: 600px) {
    .mcmcplot {
        margin: 2px;
        width: 100%;
    }
}

.input-group-text {
    width: 50%;
}

/* card-col */
.card-col {
    width: 100%;
}

/* card-body */
.card-body {
    padding: 2px;
}

/* form-control */
.form-control {
    font-size: 16px;
    text-align: center;
    padding: 6px;
    width: 100%;
}

/* secondary-button */
.secondary-button {
    font-size: 16px;
    text-align: center;
    border: none;
    padding: 6px;
}

.series-title {
    text-align: center;
    font-size: 16px;
    margin: 12px;
}

@media (max-width: 1000px) {
    .form-control {
        font-size: 14px;
    }
    .secondary-button {
        font-size: 14px;
    }
}
@media (max-width: 800px) {
    .form-control {
        font-size: 12px;
        padding: 4px;
    }
    .secondary-button {
        font-size: 12px;
        padding: 4px;
    }
    .series-title {
        font-size: 14px;
        margin: 10px;
    }
}
@media (max-width: 600px) {
    .form-control {
        font-size: 11px;
    }
    .series-title {
        font-size: 12px;
        margin: 8px;
    }
}

/* form-bordercontrol */
.form-bordercontrol.form-control  {
    font-size: 16px;
    text-align: center;
    border-style: solid;
    border-color:  lightgray;
    border-radius: 4px;
    border-width:  1px;
    padding: 4px;
}
@media (max-width: 800px) {
    .form-bordercontrol.form-control  {
        font-size: 12px;
        padding: 2px;
    }
}

.form-left.form-control  {
    text-align: left;
}

/* form-control-label */
.form-control-label {
    font-size: 16px;
    text-align: center;
    padding: 8px;
}
@media (max-width: 900px) {
    .form-control-label {
        font-size: 15px;
    }
}
@media (max-width: 800px) {
    .form-control-label {
        font-size: 14px;
        padding: 4px;
    }
}
@media (max-width: 600px) {
    .form-control-label {
        font-size: 13px;
    }
}
@media (max-width: 500px) {
    .form-control-label {
        font-size: 12px;
    }
}

.form-postestimation {
    width: 80%;
    margin: auto;
    padding: 8px;
    padding-bottom: 12px;
}
@media (max-width: 600px) {
    .form-postestimation {
        width: 100%;
        padding: 4px;
        padding-bottom: 6px;
    }
}
